
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import { createVNode, defineComponent, reactive, ref, UnwrapRef, onMounted, toRefs, watch } from 'vue'
import { ExclamationCircleOutlined, ArrowLeftOutlined, ArrowRightOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { notification, Modal } from 'ant-design-vue'
import { useRoute } from 'vue-router'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import moment from 'moment'

import validate from '../../../services/validator'
import profileService from '../../../services/profile'
import courseService from '../../../services/course'
import subscriptionService from '../../../services/subscription'
import router from '../../../router'

import IClient from '../../../interface/profile'
import IProfileType from '../../../interface/profileType'
import ICourse from '../../../interface/course'

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  percent?: number;
  url?: string;
  preview?: string;
  originFileObj?: any;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}

interface Subscription {
  payment_type: string;
  subscriptionType?: string;
  quantity?: number;
  date?: Date;
  amount?: number;
  subscription_id: string;
  profile_id: string;
  type: string;
}

function getBase64 (file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export default defineComponent({
  components: {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    // HeartOutlined,
    // UnorderedListOutlined,
    PlusOutlined,
    DeleteOutlined,
    VueGoogleAutocomplete
  },
  props: ['editClient'],
  setup (props) {
    const route = useRoute()
    const clientId = ref<string>(route.params.id as string)
    const steps = [
      {
        title: 'Client Details',
        slug: 'client_details'
      },
      {
        title: 'Course',
        slug: 'course'
      },
      {
        title: 'Subscription',
        slug: 'subscription'
      }
    ]
    const visible = ref(false)
    const current = ref<number>(0)
    const clientFormRef = ref()
    const subscriptionFormRef = ref()
    const clientRules = validate.clientValidation
    const clientSubscriptionRules = validate.clientSubscriptionValidation
    const loading = ref<boolean>(false)
    const profileType = ref<IProfileType>()
    const courses = ref<Array<ICourse>>([])
    const assignedCourses = ref<Array<ICourse>>([])
    const client = ref<IClient>()
    const availableCourse = ref([])
    const previewVisible = ref<boolean>(false)
    const addressExist = ref<boolean>(false)
    const editFlag = ref<boolean>(false)
    const previewImage = ref<string | undefined>('')
    const paymentType = ref<string>('')
    const subscriptionType = ref<string>('')
    const disablePaymentType = ref<boolean>(false)
    const disableSubscriptionType = ref<boolean>(false)
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const columns = [
      {
        title: 'Course Name',
        dataIndex: 'course.title',
        onFilter: (value, record) => record.course.title.indexOf(value) === 0,
        sorter: (a, b) => a.course.title.length - b.course.title.length,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Course Price',
        dataIndex: 'course.price',
        onFilter: (value, record) => record.course.price.indexOf(value) === 0,
        sorter: (a, b) => a.course.price.length - b.course.price.length,
        sortDirections: ['descend', 'ascend']
      },
      {
        title: 'Enrolled Date',
        dataIndex: 'enrolled_date',
        customRender: ({ text }) => {
          return moment(text).format('DD-MM-YYYY HH:mm:ss')
        }
      },
      {
        title: 'Action',
        key: 'action',
        slots: { customRender: 'action' }
      }
    ]
    const state = reactive({
      indeterminate: false,
      checkAll: false,
      checkedList: []
    })
    const clientModel: UnwrapRef<IClient> = reactive({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      profile_image: '',
      client_name: '',
      client_logo: '',
      certificate_access: true,
      created_by: '',
      profile_type: '',
      is_active: true,
      status: 'unpaid',
      send_login: false,
      address: '',
      address_details: {}
    })
    const subscriptionModel: UnwrapRef<Subscription> = reactive({
      payment_type: '',
      subscriptionType: '',
      amount: 0,
      type: '',
      quantity: 0,
      date: new Date(),
      subscription_id: '',
      profile_id: ''
    })
    const completeSetup = async (values: Subscription) => {
      values.subscription_id = `offline-${clientId.value}`
      values.profile_id = clientId.value
      values.quantity = +values.quantity
      values.type = (values.subscriptionType === '1' ? 'Credit' : 'Subscription')
      values.amount = 0
      values.date = new Date()
      values.payment_type = (values.payment_type === '1' ? 'Online' : 'Offline')
      try {
        loading.value = true
        if (values.quantity > 0 && values.subscriptionType && values.payment_type) {
          await subscriptionService.addOfflineSubscription(values)
        }
        loading.value = false
        await notify('Success', 'Client setup Successfully ', 'success')
        router.push('/clients')
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const showCourseList = async () => {
      visible.value = true
    }
    const closeCourseList = () => {
      visible.value = false
    }
    const headers = ref<object>({
      token: localStorage.accessToken
    })
    const imageUploadUrl = process.env.VUE_APP_API_URL + process.env.VUE_APP_FILE_UPLOAD
    const fileList = ref<FileItem[]>([])
    const handleCancel = () => {
      previewVisible.value = false
    }
    const handlePreview = async (file: FileItem) => {
      if (!file.url && !file.preview) {
        file.preview = (await getBase64(file.originFileObj)) as string
      }
      previewImage.value = file.url || file.preview
      previewVisible.value = true
    }
    const handleChange = ({ fileList: newFileList }: FileInfo) => {
      if (fileList.value.length > 0) {
        const s3Response: any = fileList.value[0].response
        fileList.value = newFileList
        if (s3Response) {
          clientModel.client_logo = s3Response.Location
        }
      }
    }
    const handleRemove = (file: FileItem) => {
      const index = fileList.value.indexOf(file)
      const newFileList = fileList.value.slice()
      newFileList.splice(index, 1)
      fileList.value = newFileList
      clientModel.client_logo = ''
    }
    const getClientprofileType = async () => {
      const responce = await profileService.getProfileType('client')
      profileType.value = responce.data
      clientModel.profile_type = profileType.value._id
    }
    const getAddressData = async (data) => {
      const coordinates = []
      coordinates.push(data.latitude)
      coordinates.push(data.longitude)
      clientModel.address_details.country = data.country
      clientModel.address_details.location = { coordinates: coordinates, type: 'Point' }
      clientModel.address_details.address1 = data.route
      clientModel.address_details.address2 = data.locality
      clientModel.address_details.postal_code = data.postal_code
      console.log('clientModel', clientModel)
      addressExist.value = true
    }
    const getUnAssignedCourse = async () => {
      try {
        availableCourse.value = []
        const responce = await courseService.getUnAssignedCourse(clientId.value)
        courses.value = responce.data
        for (const course of courses.value) {
          availableCourse.value.push({ label: course.title, value: course._id })
        }
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const createClient = async (values: IClient) => {
      try {
        loading.value = true
        if (addressExist.value) {
          const address = await profileService.addAddress(values.address_details)
          values.address = address.data._id
        } else {
          await notify('Error', 'Address is required to create client', 'error')
          loading.value = false
          return
        }
        const responce = await profileService.createNewClient(values)
        if (typeof responce.data === 'string') {
          await notify('Error', responce.data, 'error')
        } else {
          client.value = responce.data
          clientId.value = responce.data._id
          current.value++
          getUnAssignedCourse()
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const updateClient = async (values: IClient) => {
      try {
        loading.value = true
        // if (values.address_details.address1) {
        //   const address = await profileService.addAddress(values.address_details)
        //   values.address = address.data._id
        // }
        const address_details: any = values.address
        values.address = address_details._id
        console.log('values', values)
        const responce = await profileService.updateProfile(values._id, values)
        client.value = responce.data
        current.value++
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const getAssignedCourse = async () => {
      try {
        const responce = await courseService.getAssignedCourse(clientId.value)
        assignedCourses.value = responce.data
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const onCheckAllChange = (e: any) => {
      const aCourse = []
      for (const course of availableCourse.value) {
        aCourse.push(course.value)
      }
      Object.assign(state, {
        checkedList: e.target.checked ? aCourse : [],
        indeterminate: false
      })
    }
    const assignCourse = async () => {
      if (state.checkedList.length === 0) {
        current.value++
        closeCourseList()
      }
      const enroll = []
      try {
        loading.value = true
        for (const courseId of state.checkedList) {
          enroll.push({ enrolled_date: new Date(), profile: client.value._id, course: courseId })
        }
        await courseService.enrollMultipleCourse(enroll)
        loading.value = false
        closeCourseList()
        getAssignedCourse()
        getUnAssignedCourse()
        Object.assign(state, {
          indeterminate: false
        })
        // current.value++
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const unAssignCourse = async (id, index) => {
      try {
        await courseService.unAssignCourse(id)
        assignedCourses.value.splice(index, 1)
        getUnAssignedCourse()
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const getClientDetails = async (id) => {
      try {
        const responce = await profileService.getProfileDetails(id)
        client.value = responce.data
        if (props.editClient) {
          Object.assign(clientModel, responce.data)
        }
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const next = () => {
      if (current.value === 0) {
        clientFormRef.value
          .validate()
          .then(() => {
            if (props.editClient) {
              updateClient(clientModel)
            } else {
              createClient(clientModel)
            }
          })
          .catch((error: ValidateErrorEntity<IClient>) => {
            console.log('error', error)
          })
      }
      if (current.value === 1) {
        current.value++
      }
      if (current.value === 2) {
        Object.assign(subscriptionModel, {
          payment_type: client.value.payment_type
        })
        if (client.value.subscription) {
          if (client.value.payment_type) {
            disablePaymentType.value = true
          }
          if (client.value.payment_type === 'Online') {
            Object.assign(subscriptionModel, {
              payment_type: '1'
            })
          } else {
            Object.assign(subscriptionModel, {
              payment_type: '2'
            })
          }
          if (client.value.subscription.type) {
            disableSubscriptionType.value = true
          }
          if (client.value.subscription.type === 'Credit') {
            Object.assign(subscriptionModel, {
              subscriptionType: '1'
            })
          } else {
            Object.assign(subscriptionModel, {
              subscriptionType: '2'
            })
          }
        }
      }
    }
    const prev = () => {
      current.value--
    }
    const showDeleteConfirm = async (course, index) => {
      Modal.confirm({
        title: 'Are you sure?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode('div', { style: 'color:red;' }, 'Do you really want to un assign this course?.'),
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        async onOk () {
          unAssignCourse(course._id, index)
        },
        onCancel () {
          console.log('Cancel')
        }
      })
    }
    const handlePaymantType = async () => {
      console.log('paymentType', paymentType.value)
    }
    const handleSubscriptionType = async () => {
      console.log('subscriptionType', subscriptionType.value)
    }
    watch(() => state.checkedList, val => {
      state.indeterminate = !!val.length && val.length < availableCourse.value.length
      state.checkAll = val.length === availableCourse.value.length
      console.log('state', state)
    })
    onMounted(() => {
      getClientprofileType()
      if (props.editClient) {
        editFlag.value = true
        getUnAssignedCourse()
        getClientDetails(clientId.value)
        getAssignedCourse()
      }
    })
    return {
      steps,
      current,
      next,
      prev,
      clientFormRef,
      clientModel,
      clientRules,
      loading,
      showCourseList,
      closeCourseList,
      visible,
      state,
      ...toRefs(state),
      onCheckAllChange,
      availableCourse,
      assignCourse,
      columns,
      showDeleteConfirm,
      assignedCourses,
      getAddressData,
      imageUploadUrl,
      handleRemove,
      previewVisible,
      previewImage,
      fileList,
      handleCancel,
      handlePreview,
      handleChange,
      headers,
      editFlag,
      handlePaymantType,
      paymentType,
      subscriptionType,
      handleSubscriptionType,
      subscriptionModel,
      subscriptionFormRef,
      clientSubscriptionRules,
      completeSetup,
      disablePaymentType,
      disableSubscriptionType
    }
  }
})
